'use client';

import classnames from 'classnames';
import { useSearchParams } from 'next/navigation';
import { type PropsWithChildren, useEffect, useRef, useState, useTransition } from 'react';
import { VisuallyHidden } from 'react-aria';
import { Dialog } from 'react-aria-components';

import { Loader, ScrollableContainer, useUserContext } from '@module/mdrt-org/shared/components';
import { usePathname, useRouter } from '@module/mdrt-org/shared/i18n';
import {
  SEARCH_PAGE_PARAM_KEY,
  SEARCH_SAVED_CONTENT_PARAM_KEY,
  SEARCH_SOURCE_PARAM_KEY,
  SEARCH_TOPIC_PARAM_KEY,
  SEARCH_YEAR_PARAM_KEY,
} from '@module/mdrt-org/shared/utils/constants';
import { type LearnSearchResults } from '@module/mdrt-org/shared/utils/data/graphql';
import { useBreakpoint } from '@module/mdrt-org/shared/utils/hooks';
import { Chip, ChipTheme, FilterIcon, ModalTrigger } from '@shared/ui-components';
import {
  getPathnameWithPartialParameters,
  GTM_EVENT_NAME_VIEW_SEARCH_RESULTS,
  sendGtmEvent,
} from '@shared/utils';

import { type SearchCmsContent } from '../../graphql/get-search-cms-content';
import { type SearchPageSearchParameters } from '../../types';
import {
  getGtmSearchResultsViewEventValues,
  getValidSearchParameters,
  transformFilterValuesToSearchParameters,
} from '../../utils';
import { useAppliedFilterCount } from '../../utils/hooks';
import { LoginAlert } from '../login-alert';
import { SearchAdvancedFilter } from '../search-advanced-filter/search-advanced-filter';
import { type AdvancedFilterFormData } from '../search-advanced-filter/types/advanced-filter-form-data';
import { useSearchAdvancedFilter } from '../search-advanced-filter/use-search-advanced-filter';
import { SearchBar } from '../search-bar';
import { SearchPagination } from '../search-pagination/search-pagination';
import { SearchTypeFilter } from '../search-type-filter';

import styles from './search-controls.module.scss';

type SearchControlsProps = {
  contentType: number | null;
  pageIndex: number;
  pageTitle: string;
  searchCmsContent: SearchCmsContent;
  searchResults: LearnSearchResults;
};

export const SearchControls = ({
  children,
  contentType,
  pageIndex,
  pageTitle,
  searchCmsContent,
  searchResults,
}: PropsWithChildren<SearchControlsProps>) => {
  const [isPending, startTransition] = useTransition();
  const { status } = useUserContext();
  const isAuthenticated = status === 'authenticated';
  const [showSideFilterSection, setShowSideFilterSection] = useState(false);
  const searchParameters = useSearchParams();
  const pathname = usePathname();
  const router = useRouter();
  const { breakpoint, isDesktop } = useBreakpoint();
  const appliedFilterCount = useAppliedFilterCount(isAuthenticated);
  const filtersButtonDesktopRef = useRef<HTMLButtonElement>(null);
  const [isSearchUpdated, setIsSearchUpdated] = useState(false);
  const filterState = useSearchAdvancedFilter({ facets: searchResults.facets });

  const { total } = searchResults;
  const totalResults = total || 0;

  const updateSearchParameters = (parametersToUpdate: SearchPageSearchParameters) => {
    setIsSearchUpdated(true);
    const pathWithQuery = getPathnameWithPartialParameters({
      partialParameters: {
        [SEARCH_PAGE_PARAM_KEY]: '',
        ...parametersToUpdate,
      },
      pathname,
      searchParameters,
    });

    startTransition(() => {
      router.push(pathWithQuery);
    });
  };

  const filtersLabel = `${searchCmsContent.searchPage?.FiltersLabel || 'Filters'}${
    appliedFilterCount ? ` (${appliedFilterCount})` : ''
  }`;

  const handleDesktopFiltersClose = () => {
    setShowSideFilterSection(false);

    filtersButtonDesktopRef?.current?.focus();
  };

  const handleAdvancedFilterSubmit = (formData: AdvancedFilterFormData, callback?: () => void) => {
    const { savedContent } = formData.savedContent;
    updateSearchParameters({
      [SEARCH_SAVED_CONTENT_PARAM_KEY]: savedContent ? savedContent.toString() : '',
      [SEARCH_SOURCE_PARAM_KEY]: transformFilterValuesToSearchParameters(formData.source),
      [SEARCH_TOPIC_PARAM_KEY]: transformFilterValuesToSearchParameters(formData.topic),
      [SEARCH_YEAR_PARAM_KEY]: transformFilterValuesToSearchParameters(formData.year),
    });

    callback?.();
  };

  const handleAdvancedFilterReset = (callback?: () => void) => {
    if (appliedFilterCount) {
      updateSearchParameters({
        [SEARCH_SAVED_CONTENT_PARAM_KEY]: '',
        [SEARCH_SOURCE_PARAM_KEY]: '',
        [SEARCH_TOPIC_PARAM_KEY]: '',
        [SEARCH_YEAR_PARAM_KEY]: '',
      });
    }

    callback?.();
  };

  useEffect(() => {
    const delay = setTimeout(() => {
      const validSearchParameters = getValidSearchParameters(searchParameters);
      if (Object.values(validSearchParameters).length) {
        sendGtmEvent({
          eventName: GTM_EVENT_NAME_VIEW_SEARCH_RESULTS,
          searchResultsValues: getGtmSearchResultsViewEventValues(validSearchParameters),
        });
      }
    }, 0);
    return () => clearTimeout(delay);
  }, [searchParameters]);

  return (
    <>
      {isPending && <Loader allowScroll className={styles.loader} fixedToViewportCenter isInner />}
      <div
        className={classnames(styles.wrapper, {
          [styles['wrapper--filters-open']]: showSideFilterSection,
        })}
      >
        <VisuallyHidden elementType="h1">{pageTitle}</VisuallyHidden>
        <SearchBar
          onSearchQueryChange={updateSearchParameters}
          searchPageCmsContent={searchCmsContent.searchPage}
        />
        <ScrollableContainer className={styles.toolbar} role="toolbar">
          <div className={styles['filters-button-wrapper']}>
            {isDesktop ? (
              <Chip
                aria-controls="learn-filters-desktop"
                aria-expanded={showSideFilterSection}
                className={styles['toolbar__filter-button']}
                icon={<FilterIcon />}
                label={filtersLabel}
                onClick={() => setShowSideFilterSection(!showSideFilterSection)}
                ref={filtersButtonDesktopRef}
                theme={ChipTheme.Light}
              />
            ) : (
              <ModalTrigger
                renderTrigger={({ handleModalOpen }) => (
                  <Chip
                    className={styles['toolbar__filter-button']}
                    disabled={!breakpoint}
                    icon={<FilterIcon />}
                    label={filtersLabel}
                    onClick={handleModalOpen}
                    theme={ChipTheme.Light}
                  />
                )}
              >
                {(handleClose) => (
                  <Dialog aria-label={filtersLabel}>
                    <SearchAdvancedFilter
                      className={classnames(styles.filters, styles['filters--mobile'])}
                      filterState={filterState}
                      isMobile
                      onClose={handleClose}
                      onReset={() => handleAdvancedFilterReset(handleClose)}
                      onSubmit={(formData) => handleAdvancedFilterSubmit(formData, handleClose)}
                      searchCmsContent={searchCmsContent}
                    />
                  </Dialog>
                )}
              </ModalTrigger>
            )}
          </div>
          <SearchTypeFilter
            contentType={contentType}
            onContentTypeChange={updateSearchParameters}
            searchPageCmsContent={searchCmsContent.searchPage}
          />
        </ScrollableContainer>
        <LoginAlert
          className={styles.alert}
          isSearchUpdated={isSearchUpdated}
          message={searchCmsContent.learnPage.signInAlertText}
          totalResults={totalResults}
        />
        {isDesktop && (
          <div className={styles['filters-wrapper']}>
            <SearchAdvancedFilter
              className={classnames(styles.filters, {
                [styles['filters--is-open']]: showSideFilterSection,
              })}
              filterState={filterState}
              id="learn-filters-desktop"
              isOpen={showSideFilterSection}
              onClose={handleDesktopFiltersClose}
              onReset={handleAdvancedFilterReset}
              onSubmit={handleAdvancedFilterSubmit}
              searchCmsContent={searchCmsContent}
            />
          </div>
        )}
        {children}
        {Boolean(total) && (
          <SearchPagination
            genericCmsContent={searchCmsContent.generic}
            onPageChange={updateSearchParameters}
            pageIndex={pageIndex}
            totalCount={total}
          />
        )}
      </div>
    </>
  );
};
