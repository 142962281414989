'use client';

import { type ChangeEvent } from 'react';
import { useForm } from 'react-hook-form';

import { Chip, FieldController, Loader, useUserContext } from '@module/mdrt-org/shared/components';
import { patchAccountSettingsAction } from '@module/mdrt-org/shared/utils/data/patch-account-settings-action';
import { AccountSettingsFormSection } from '@module/mdrt-org/shared/utils/enums';
import { AccountSettingsFormStep } from '@module/mdrt-org/shared/utils/enums/account-settings-form-step';
import { AccountSettingsMutationKey } from '@module/mdrt-org/shared/utils/enums/account-settings-mutation-key';
import { loginHandler } from '@module/mdrt-org/shared/utils/helpers';
import {
  useAddDefaultErrorToast,
  useConfirmNavigation,
  useMutationHandler,
} from '@module/mdrt-org/shared/utils/hooks';
import { USERNAME_REGEXP } from '@module/mdrt-org/shared/utils/types';
import { AuthProviderName, parseJwtToken } from '@shared/misc';
import { Button, CheckboxInput, ChipTheme, FormInput, FormWrapper } from '@shared/ui-components';
import {
  ButtonType,
  CHANGE_USERNAME_TO_BE_UNIQUE_ERROR_ID,
  type ServerActionResponse,
} from '@shared/utils';

import { useAccountSettingsPageContext } from '../../providers';
import { MAX_USERNAME_LENGTH } from '../../utils/constants/account-settings';
import { type LoginPasswordDTO, type LoginPasswordFormData } from '../../utils/types/form-data';

import { type AccountSettingsLoginPasswordContent } from './get-account-settings-login-password-content';
import { SocialLogins } from './social-logins';
import styles from './login-password-form.module.scss';

type LoginPasswordFormProps = {
  cmsContent: AccountSettingsLoginPasswordContent;
  locale: string;
  tabUrl: string;
};

const filterUsernameByRegex = {
  input: (value: string) => {
    return value;
  },
  output: (event: ChangeEvent<HTMLInputElement>) => {
    return event.target.value.match(USERNAME_REGEXP)?.join('') || '';
  },
};

export const LoginPasswordForm = ({ cmsContent, locale, tabUrl }: LoginPasswordFormProps) => {
  const {
    customerProfileContextData,
    resetCustomerProfileContextData,
    setCustomerProfileContextData,
  } = useAccountSettingsPageContext();
  const { accessToken, personId, userId } = useUserContext();
  const addErrorToast = useAddDefaultErrorToast();

  const parsedToken = accessToken ? parseJwtToken(accessToken) : undefined;
  const isBiometricSwitchEnabled =
    parsedToken !== undefined && parsedToken?.credentialId !== undefined;

  const formMethods = useForm<LoginPasswordFormData>({
    defaultValues: {
      emailAddress: customerProfileContextData.emailAddress,
      mdrtId: customerProfileContextData.mdrtId,
      username: customerProfileContextData.username,
    },
    mode: 'onSubmit',
  });
  const onFailure = (result: ServerActionResponse) => {
    resetCustomerProfileContextData();
    if (
      result &&
      'messageTranslationKey' in result &&
      result.messageTranslationKey === CHANGE_USERNAME_TO_BE_UNIQUE_ERROR_ID
    ) {
      formMethods.setError('username', {
        message:
          result.messageTranslationKey === CHANGE_USERNAME_TO_BE_UNIQUE_ERROR_ID
            ? cmsContent.usernameNotUniqueError
            : result.message,
        type: CHANGE_USERNAME_TO_BE_UNIQUE_ERROR_ID,
      });
    } else if (result.status !== 200 && 'message' in result) {
      addErrorToast();
    }
  };

  const {
    formState: { isDirty },
    reset,
  } = formMethods;

  const { isMutationPending, mutate } = useMutationHandler({
    mutationFunction: patchAccountSettingsAction<LoginPasswordDTO>,
    mutationKey: [AccountSettingsMutationKey.PATCH_ACCOUNT_SETTINGS],
    onFailure,
    onSuccessHandler: () => reset({ ...formMethods.getValues() }),
  });

  const handleSubmit = async (data: LoginPasswordFormData) => {
    setCustomerProfileContextData({
      ...customerProfileContextData,
      username: data.username,
    });

    mutate({
      formData: {
        accountSettingStep: AccountSettingsFormStep.LoginPassword,
        loginData: {
          username: data.username,
        },
      },
      personId,
    });
  };

  useConfirmNavigation(isDirty, tabUrl);

  return (
    <>
      <FormWrapper
        className={styles['login-password-form']}
        dataTestId="personal-info-form"
        formMethods={formMethods}
        onSubmit={handleSubmit}
      >
        <h1 className={styles['login-password-form__heading']}>{cmsContent.tabTitle}</h1>
        <FieldController<LoginPasswordFormData>
          hint={cmsContent.emailEditInformation}
          label={cmsContent.email}
          name="emailAddress"
        >
          <FormInput disabled />
        </FieldController>
        <FieldController<LoginPasswordFormData> label={cmsContent.mdrtId} name="mdrtId">
          <FormInput disabled />
        </FieldController>
        <FieldController<LoginPasswordFormData>
          label={cmsContent.userName}
          name="username"
          rules={{
            required: cmsContent.requiredLabel,
          }}
          transform={filterUsernameByRegex}
        >
          <FormInput maxLength={MAX_USERNAME_LENGTH} />
        </FieldController>
        <Chip
          className={styles['login-password-form__reset-password-button']}
          label={cmsContent.resetPassword}
          onClick={() =>
            loginHandler({
              authorizationParameters: {
                ui_locales: locale,
                userId,
              },
              options: { redirectToRelative: cmsContent.pagePaths.accountSettingPagePath },
              provider: AuthProviderName.PasswordReset,
            })
          }
          theme={ChipTheme.Light}
        />
        <div className={styles['login-password-form__footer']}>
          <Button disabled={!isDirty || isMutationPending} type={ButtonType.SUBMIT}>
            {cmsContent.saveChanges}
          </Button>
        </div>
        {isMutationPending && <Loader isInner />}
      </FormWrapper>
      <div className={styles['biometric-switch']}>
        <CheckboxInput
          id="biometric-switch"
          isChecked={isBiometricSwitchEnabled}
          isSwitch
          label={cmsContent.biometricsSwitchLabel}
          name="biometric-switch"
          onChange={() =>
            loginHandler({
              authorizationParameters: {
                ui_locales: locale,
              },
              options: {
                redirectToRelative: `${cmsContent.pagePaths.accountSettingPagePath}#${AccountSettingsFormSection.LoginPassword}`,
              },
              provider: isBiometricSwitchEnabled
                ? AuthProviderName.BiometricDeregister
                : AuthProviderName.BiometricRegistration,
            })
          }
        />
      </div>
      <SocialLogins cmsContent={cmsContent} locale={locale} />
    </>
  );
};
