'use client';

import { type ButtonProps, ArrowIcon, ButtonVariant } from '@shared/ui-components';

import { useRouter } from '../../i18n/i18n-navigation';
import { Button } from '../button';

import styles from './back-button.module.scss';

export const BackButton = ({ children, to, ...rest }: Omit<ButtonProps, 'LinkComponent'>) => {
  const router = useRouter();

  const buttonProps = {
    ...rest,
    ...(to ? { to } : { onClick: () => router.back() }),
  };

  return (
    <Button
      isSmall
      prefix={<ArrowIcon className={styles['button__icon']} />}
      variant={ButtonVariant.Tertiary}
      {...buttonProps}
    >
      {children}
    </Button>
  );
};
