import { type MemberDirectoryPageContent } from '../get-member-directory-page-content';

import styles from './no-results-card.module.scss';

export const NoResultsCard = ({ cmsContent }: { cmsContent: MemberDirectoryPageContent }) => {
  return (
    <div className={styles['card']}>
      <p className={styles['card__message-title']}>{cmsContent.noResultsTitle}</p>
      <p className={styles['card__message']}>{cmsContent.noResultsMessage}</p>
    </div>
  );
};
