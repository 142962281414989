import { Button } from '@module/mdrt-org/shared/components/button';
import { type CustomerProfile } from '@module/mdrt-org/shared/utils/types';
import { ButtonVariant } from '@shared/ui-components';
import {
  formatTranslationStringOnClient,
  GTM_ACTION_DOWNLOAD,
  GTM_EVENT_NAME_PDF_DOWNLOAD,
  sendGtmEvent,
} from '@shared/utils';

import { type AccountSettingsProfileContent } from '../get-account-settings-profile-content';

import styles from './membership-printouts.module.scss';

type MembershipPrintoutsProps = {
  cmsContent: AccountSettingsProfileContent;
  customerProfile: CustomerProfile;
  isMember: boolean;
  membershipCertificateEndpointUrl: string;
};

export const MembershipPrintouts = ({
  cmsContent,
  customerProfile,
  isMember,
  membershipCertificateEndpointUrl,
}: MembershipPrintoutsProps) => {
  const membershipYear = customerProfile.lastYearOfMembership;

  if (!isMember) return null;

  return (
    <div className={styles['container']}>
      <Button
        className={styles['container__button']}
        onClick={() => {
          window.open(membershipCertificateEndpointUrl, '_blank');
          sendGtmEvent({
            action: GTM_ACTION_DOWNLOAD,
            eventName: GTM_EVENT_NAME_PDF_DOWNLOAD,
            // TODO: No translation yet, however, at some point 'Account Profile (legacy)' Certificate tab fields should be moved to another page, so 'Pdf File Name' field value should be used here
            label: `${membershipYear} Membership Certificate`,
          });
        }}
        role="link"
      >
        {formatTranslationStringOnClient(
          cmsContent.membershipPrintouts.getMembershipCertificateLabelTemplate,
          { year: membershipYear }
        )}
      </Button>

      <Button
        isSmall
        to={cmsContent.membershipPrintouts.pagePaths.accountSettingPagePath}
        variant={ButtonVariant.Tertiary}
      >
        {formatTranslationStringOnClient(
          cmsContent.membershipPrintouts.viewMembershipInvoiceLabelTemplate,
          { year: membershipYear }
        )}
      </Button>
    </div>
  );
};
