'use client';

import { useSearchParams } from 'next/navigation';
import { useMemo, useRef } from 'react';
import { useForm } from 'react-hook-form';

import { type MembershipLevel } from '@module/mdrt-org/shared/utils/enums';
import { getQueryParametersStringFromObject } from '@module/mdrt-org/shared/utils/helpers/getQueryParametersStringFromObject';
import { type Designation, type State } from '@module/mdrt-org/shared/utils/types';
import { Pagination, type SelectOption } from '@shared/ui-components';
import { formBaseConfig } from '@shared/utils';

import { type SearchMembersRequest } from '../../utils/types';
import { type MemberDirectoryPageContent } from '../get-member-directory-page-content';
import { SearchForm } from '../search-form/search-form';
import { NoResultsCard } from '../search-results/no-results-card';
import { SearchResults } from '../search-results/search-results';
import { useMemberDirectorySearchQuery } from '../use-member-directory-search-query';

import styles from './search.module.scss';

export type SearchFormData = {
  city: string;
  country: Nullable<SelectOption<number>>;
  membershipLevel: Nullable<SelectOption<string>>;
  searchQuery: string;
  stateOrProvince: Nullable<SelectOption<number>>;
};

export const Search = ({
  canFilterByMembershipLevel = false,
  cmsContent,
  countryOptions,
  defaultValues,
  designations,
  initialCountryStatesData,
  membershipLevelOptions,
}: {
  canFilterByMembershipLevel: boolean;
  cmsContent: MemberDirectoryPageContent;
  countryOptions: Array<SelectOption<number>>;
  defaultValues: SearchFormData;
  designations: Designation[];
  initialCountryStatesData: State[] | undefined;
  membershipLevelOptions: Array<SelectOption<MembershipLevel>>;
}) => {
  const searchParameters = useSearchParams();

  const resultsListHeaderRef = useRef<HTMLHeadingElement>(null);

  const formMethods = useForm<SearchFormData>({
    ...formBaseConfig,
    defaultValues,
  });

  const getParameter = <T extends keyof SearchMembersRequest>(
    key: T,
    // eslint-disable-next-line @typescript-eslint/no-shadow
    transform: (value: string) => SearchMembersRequest[T] = (value) =>
      value as SearchMembersRequest[T]
  ) => {
    const value = searchParameters.get(key);
    return value ? { [key]: transform(value) } : {};
  };

  const searchParametersString = searchParameters.toString();
  const requestData: SearchMembersRequest = useMemo(
    () => ({
      ...getParameter('city'),
      ...getParameter('countryId', Number),
      ...(canFilterByMembershipLevel && getParameter('membershipLevel')),
      ...getParameter('page', Number),
      ...getParameter('searchQuery'),
      ...getParameter('stateOrProvinceId', Number),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchParametersString]
  );

  const handleSearchRequestDataUpdate = (newRequestData: SearchMembersRequest) => {
    const urlWithSearchParameters = getQueryParametersStringFromObject({
      ...requestData,
      ...newRequestData,
    });
    window.history.pushState(null, '', `?${urlWithSearchParameters}`);

    // Timeouts are added to fix iOS issue.
    setTimeout(() => {
      resultsListHeaderRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'center',
      });
    }, 0);
  };

  const handleSubmit = async (formData: SearchFormData) => {
    handleSearchRequestDataUpdate({
      city: formData.city ?? undefined,
      countryId: formData.country?.value ?? undefined,
      membershipLevel: formData.membershipLevel?.value ?? undefined,
      page: 1,
      searchQuery: formData.searchQuery.trim(),
      stateOrProvinceId: formData.stateOrProvince?.value ?? undefined,
    });
  };

  const { data: searchResults } = useMemberDirectorySearchQuery({ requestData });

  const currentPage = requestData.page ? Number(requestData.page) : 1;

  return (
    <div className={styles['sections-container']}>
      <div className={styles['section']}>
        <div className={styles['section__primary-content']}>
          <h1 className={styles['section__title']}>{cmsContent.pageTitle}</h1>
          <SearchForm
            canFilterByMembershipLevel={canFilterByMembershipLevel}
            cmsContent={cmsContent}
            countryOptions={countryOptions}
            formMethods={formMethods}
            initialCountryStatesData={initialCountryStatesData}
            membershipLevelOptions={membershipLevelOptions}
            onSubmit={handleSubmit}
          />
        </div>
        {/* todo Members Near By block <div className={styles['section__secondary-content']} />*/}
      </div>
      {searchResults &&
        (searchResults.count > 0 && searchResults.users.length > 0 ? (
          <div className={styles['results-section']}>
            <SearchResults
              cmsContent={cmsContent}
              designations={designations}
              resultsListHeaderRef={resultsListHeaderRef}
              searchResults={searchResults}
            />
            <div className={styles['pagination-container']}>
              <Pagination
                blurButtonsOnPageChange
                currentPage={currentPage}
                label={{
                  nextPage: cmsContent.nextLinkLabel,
                  pagination: cmsContent.paginationLabel,
                  previousPage: cmsContent.previousPageLabel,
                  totalItemsCount: cmsContent.totalItemsCountLabel,
                }}
                maxItemsPerPage={24}
                onPageChange={async (page) => {
                  handleSearchRequestDataUpdate({ page });
                }}
                totalItemsCount={searchResults?.count}
              />
            </div>
          </div>
        ) : (
          (searchResults?.count === 0 || searchResults.users.length === 0) && (
            <NoResultsCard cmsContent={cmsContent} />
          )
        ))}
    </div>
  );
};
