'use client';

import { useEffect, useState } from 'react';

import { type InterestsData } from '@module/mdrt-org/shared/utils/data/graphql/utils';
import { ModalTrigger } from '@shared/ui-components';

import { type InterestsFormModalCmsContent } from './interests-form-modal-cms-content-type';
import { InterestsFormModalDialog } from './interests-form-modal-dialog';

const LEARN_INTERESTS_DISMISSED_KEY = 'learn-interests-dismissed';
const LEARN_INTERESTS_DISMISSED_VALUE = 'true';

const onDismiss = () => {
  if (sessionStorage) {
    sessionStorage.setItem(LEARN_INTERESTS_DISMISSED_KEY, LEARN_INTERESTS_DISMISSED_VALUE);
  }
};

type InterestsFormModalProps = {
  cmsContent: InterestsFormModalCmsContent;
  customerInterests: number[];
  topics: InterestsData;
};

export const InterestsFormModalWrapper = ({
  cmsContent,
  customerInterests,
  topics,
}: InterestsFormModalProps) => {
  const [isInterestPreferencesModalOpen, setIsInterestPreferencesModalOpen] = useState<boolean>(
    customerInterests.length === 0
  );

  const [isModalOpen, setIsModalOpen] = useState(false);
  useEffect(() => {
    setIsModalOpen(sessionStorage.getItem(LEARN_INTERESTS_DISMISSED_KEY) === null);
  }, []);

  return (
    isModalOpen && (
      <ModalTrigger isOpen={isInterestPreferencesModalOpen}>
        {() => (
          <InterestsFormModalDialog
            allTopics={topics}
            cmsContent={cmsContent}
            customerInterests={customerInterests}
            onDismiss={onDismiss}
            onSuccessfulSubmit={() => {
              window.location.reload();
            }}
            setIsOpen={setIsInterestPreferencesModalOpen}
          />
        )}
      </ModalTrigger>
    )
  );
};
