'use client';

import classNames from 'classnames';
import { type RefObject } from 'react';

import { useGlobalCmsContentContext } from '@module/mdrt-org/shared/components';
import { Link } from '@module/mdrt-org/shared/i18n';
import { MembershipLevel } from '@module/mdrt-org/shared/utils/enums';
import { getQueryParametersStringFromObject } from '@module/mdrt-org/shared/utils/helpers/getQueryParametersStringFromObject';
import { type Designation } from '@module/mdrt-org/shared/utils/types';
import { BadgeCot, BadgeMember, BadgeTot, Card, NoPhoto } from '@shared/ui-components';
import { mapDesignationIdsToLabels } from '@shared/utils';

import { type SearchMembersResponse } from '../../utils/types';
import { type MemberDirectoryPageContent } from '../get-member-directory-page-content';

import styles from './search-results.module.scss';

export const SearchResults = ({
  cmsContent,
  designations,
  resultsListHeaderRef,
  searchResults,
}: {
  cmsContent: MemberDirectoryPageContent;
  designations: Designation[];
  resultsListHeaderRef: RefObject<HTMLHeadingElement>;
  searchResults: SearchMembersResponse;
}) => {
  const {
    cmsPaths: { memberDirectoryProfilePath },
  } = useGlobalCmsContentContext();

  return (
    <>
      <h2 className={styles['results-list-header']} ref={resultsListHeaderRef}>
        {cmsContent.resultsSectionTitle}: {searchResults.count}
      </h2>
      <div className={styles['results-list']}>
        {searchResults.users.map((person, index) => {
          return (
            <Link
              className={styles['person-card-link']}
              data-testid={`person-card-${index}`}
              href={`${memberDirectoryProfilePath}?${getQueryParametersStringFromObject({
                mdrtId: person.id,
              })}`}
              key={person.id}
            >
              <Card className={styles['person-card']} hasSmallRadius isClickable>
                <div className={styles['person-card__photo-container']}>
                  {person.originalSizePictureUri ? (
                    <img
                      alt=""
                      className={styles['person-card__photo']}
                      role="presentation"
                      src={`data:image/jpeg;base64, ${person.originalSizePictureUri}`}
                    />
                  ) : (
                    <NoPhoto className={styles['person-card__photo-placeholder-icon']} />
                  )}
                  {person.membershipLevel && (
                    <div className={styles['person-card__badge']}>
                      {
                        {
                          [MembershipLevel.COT]: <BadgeCot />,
                          [MembershipLevel.MDRT]: <BadgeMember />,
                          [MembershipLevel.TOT]: <BadgeTot />,
                        }[person.membershipLevel]
                      }
                    </div>
                  )}
                </div>
                <div className={styles['person-card__details']}>
                  {person.nickname ? (
                    <>
                      <div className={styles['person-card__name']}>{person.nickname}</div>{' '}
                      <div className={styles['person-card__name']}>{person.lastName}</div>
                    </>
                  ) : person?.firstName && person?.lastName ? (
                    <>
                      <div className={styles['person-card__name']}>{person.firstName}</div>{' '}
                      <div className={styles['person-card__name']}>{person.lastName}</div>
                    </>
                  ) : (
                    <div className={styles['person-card__name']}>{person.lastName}</div>
                  )}
                  {person?.designations?.length > 0 && (
                    <div className={styles['person-card__designations']}>
                      {mapDesignationIdsToLabels(person.designations, designations)?.join(', ')}
                    </div>
                  )}
                  {person.membershipLevel && (
                    <div
                      className={classNames(
                        styles['person-card__level-label'],
                        styles['person-card__level-label--sr-only']
                      )}
                    >
                      {`${
                        {
                          [MembershipLevel.COT]: cmsContent.cotLevelLabel,
                          [MembershipLevel.MDRT]: cmsContent.mdrtLevelLabel,
                          [MembershipLevel.TOT]: cmsContent.totLevelLabel,
                        }[person.membershipLevel]
                      } ${cmsContent.membershipLevelLabel}`}
                    </div>
                  )}
                  {person?.companyName && (
                    <div className={styles['person-card__company-name']}>{person.companyName}</div>
                  )}
                </div>
              </Card>
            </Link>
          );
        })}
      </div>
    </>
  );
};
