import { fetchData, getStringWithQueryParameters } from '@module/mdrt-org/shared/utils/helpers';
import { getPublicEnvironmentVariables } from '@module/mdrt-org/shared/utils/helpers/get-public-environment-variables';
import {
  fetchWithErrorHandling,
  withServerSideErrorHandling,
} from '@module/mdrt-org/shared/utils/http-services';
import { auth } from '@shared/misc';

import { type SearchMembersRequest, type SearchMembersResponse } from './types';

export const getMemberDirectorySearchResults = async (
  accessToken: string,
  requestData?: SearchMembersRequest,
  signal?: AbortSignal
) => {
  const publicEnvironmentVariables =
    typeof window === 'undefined' ? await getPublicEnvironmentVariables() : window.PUBLIC_ENV;

  const urlWithSearchParameters = getStringWithQueryParameters(
    `${publicEnvironmentVariables.apiBaseUrl}/${publicEnvironmentVariables.apiRoute}/SearchMembers`,
    requestData ?? {}
  );

  return await fetchWithErrorHandling<SearchMembersResponse>(urlWithSearchParameters, {
    cache: 'no-store',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Ocp-Apim-Subscription-Key': publicEnvironmentVariables.ocpApiSubscriptionKey ?? '',
    },
    signal,
  });
};

export const getMemberDirectorySearchResultsOnServer = async (
  requestData?: SearchMembersRequest
) => {
  const session = await auth();
  const accessToken = session?.accessToken ?? '';

  const urlWithSearchParameters = getStringWithQueryParameters(
    `${process.env.NEXT_PUBLIC_API_BASE_URL}/${process.env.NEXT_PUBLIC_API_API_ROUTE}/SearchMembers`,
    requestData ?? {}
  );

  const response = await withServerSideErrorHandling(
    async () =>
      await fetchData({
        apiUrl: urlWithSearchParameters,
        cache: 'no-store',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Ocp-Apim-Subscription-Key': process.env.NEXT_PUBLIC_OCP_API_SUBSCRIPTION_KEY ?? '',
        },
      })
  );

  if (response.ok) {
    return await response.json();
  }

  return undefined;
};
