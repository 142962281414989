import { HOMEPAGE_PATH } from '../../utils/constants/local-paths';
import { BackButton } from '../back-button/back-button';

export const BackToHomepageButton = ({ label }: { label: string }) => {
  return (
    <BackButton dataTestId="back-to-dashboard-link" to={HOMEPAGE_PATH}>
      {label}
    </BackButton>
  );
};
