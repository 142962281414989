import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';

import {
  useGlobalCmsContentContext,
  useGlobalLoaderContext,
  useUserContext,
} from '@module/mdrt-org/shared/components';
import { OrgSharedDataTag } from '@module/mdrt-org/shared/utils/data/constants';
import { objectHasEntries } from '@module/mdrt-org/shared/utils/helpers';
import { ToastType, useGlobalToastContext } from '@shared/ui-components';

import { getMemberDirectorySearchResults } from '../utils/get-member-directory-search-results';
import { type SearchMembersRequest } from '../utils/types';

export const useMemberDirectorySearchQuery = ({
  requestData,
}: {
  requestData: SearchMembersRequest;
}) => {
  const { accessToken } = useUserContext();
  const { addToast } = useGlobalToastContext();
  const { cmsContent } = useGlobalCmsContentContext();
  const { setIsNonBlockingLoaderVisible } = useGlobalLoaderContext();

  const {
    data,
    error,
    isError,
    isFetching: isSearchResultsDataLoading,
  } = useQuery({
    enabled: objectHasEntries(requestData) && accessToken !== undefined,
    placeholderData: keepPreviousData,
    queryFn: ({ signal }) => {
      if (accessToken === undefined) {
        return undefined;
      }

      return getMemberDirectorySearchResults(accessToken, requestData, signal);
    },
    queryKey: [OrgSharedDataTag.GET_MEMBER_DIRECTORY_SEARCH_RESULTS, requestData],
  });

  // todo use global cache handler for error toasts
  useEffect(() => {
    if (isError) {
      addToast(
        {
          closeButtonLabel: cmsContent.closeButtonLabel,
          description: cmsContent.somethingWentWrong,
          type: ToastType.ERROR,
        },
        { timeout: 0 }
      );
    }
  }, [error]);

  useEffect(() => {
    setIsNonBlockingLoaderVisible(isSearchResultsDataLoading);
  }, [isSearchResultsDataLoading, setIsNonBlockingLoaderVisible]);

  return { data };
};
