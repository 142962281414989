export const getQueryParametersStringFromObject = (
  object: Record<string, number | string | null | undefined>
): string => {
  const queryParameters = new URLSearchParams();

  for (const [key, value] of Object.entries(object)) {
    if (value !== undefined && value !== null && value !== '') {
      queryParameters.append(key, String(value));
    }
  }

  return queryParameters.toString();
};
