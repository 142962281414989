import { FieldController, SelectField } from '@module/mdrt-org/shared/components';
import { FormInput, type SelectOption } from '@shared/ui-components';

import { useAccountSettingsPageContext } from '../../providers/account-settings-page-context';
import {
  MAX_ADDRESS_LINE_LENGTH,
  MAX_CITY_LENGTH,
  MAX_ZIP_CODE_LENGTH,
} from '../../utils/constants/account-settings';
import { LATIN_ONLY_ADDRESS_REGEXP } from '@module/mdrt-org/shared/utils/types';
import { type ContactInformationFormData } from '../../utils/types/form-data';

import { type AccountSettingsContactInformationContent } from './get-account-settings-contact-information-content';
import styles from './contact-information-form.module.scss';

const PARENT_CLASSNAME = 'contact-information-form';

export const MailingAddressSection = ({
  cmsContent,
  countryOptions,
  stateOptions,
}: {
  cmsContent: AccountSettingsContactInformationContent;
  countryOptions: SelectOption[];
  stateOptions: SelectOption[];
}) => {
  const { countriesData, customerProfileContextData } = useAccountSettingsPageContext();

  const selectedMailingAddressCountry = countriesData.find(
    (country) => country.countryId === customerProfileContextData.countryId
  );

  return (
    <>
      <h2 className={styles[`${PARENT_CLASSNAME}__heading`]}>
        {cmsContent.mailingAddressTitleLabel}
      </h2>
      <SelectField
        hint={cmsContent.countryHint}
        isClearable
        isDisabled
        isSearchable
        label={cmsContent.countryLabel}
        name="countryId"
        options={countryOptions}
      />
      <FieldController<ContactInformationFormData>
        label={cmsContent.addressLine1Label}
        name="addressLineOne"
        rules={{
          pattern: {
            message: `${cmsContent.latinOnlyMessage}`,
            value: LATIN_ONLY_ADDRESS_REGEXP,
          },
          required: `${cmsContent.requiredLabel}`,
        }}
      >
        <FormInput maxLength={MAX_ADDRESS_LINE_LENGTH} />
      </FieldController>
      <FieldController<ContactInformationFormData>
        label={cmsContent.addressLine2Label}
        name="addressLineTwo"
        rules={{
          pattern: {
            message: `${cmsContent.latinOnlyMessage}`,
            value: LATIN_ONLY_ADDRESS_REGEXP,
          },
        }}
      >
        <FormInput maxLength={MAX_ADDRESS_LINE_LENGTH} />
      </FieldController>
      <FieldController<ContactInformationFormData>
        label={cmsContent.addressLine3Label}
        name="addressLineThree"
        rules={{
          pattern: {
            message: `${cmsContent.latinOnlyMessage}`,
            value: LATIN_ONLY_ADDRESS_REGEXP,
          },
        }}
      >
        <FormInput maxLength={MAX_ADDRESS_LINE_LENGTH} />
      </FieldController>
      <FieldController<ContactInformationFormData>
        label={cmsContent.cityLabel}
        name="addressCityOrTown"
        rules={{
          pattern: {
            message: `${cmsContent.latinOnlyMessage}`,
            value: LATIN_ONLY_ADDRESS_REGEXP,
          },
          required: cmsContent.requiredLabel,
        }}
      >
        <FormInput maxLength={MAX_CITY_LENGTH} />
      </FieldController>
      <SelectField
        isClearable
        isRequired={selectedMailingAddressCountry?.stateProvinceIsRequired}
        isSearchable
        label={cmsContent.stateProvinceLabel}
        name="addressStateOrProvinceId"
        options={stateOptions}
        rules={{
          validate: {
            required: (value) =>
              !value && selectedMailingAddressCountry?.stateProvinceIsRequired
                ? cmsContent.requiredLabel
                : true,
          },
        }}
      />
      <FieldController<ContactInformationFormData>
        isRequired={selectedMailingAddressCountry?.postalCodeIsRequired}
        label={cmsContent.postalCodeLabel}
        name="addressZipCode"
        rules={{
          pattern: {
            message: cmsContent.latinOnlyMessage,
            value: LATIN_ONLY_ADDRESS_REGEXP,
          },
          validate: {
            required: (value) =>
              !value && selectedMailingAddressCountry?.postalCodeIsRequired
                ? cmsContent.requiredLabel
                : true,
          },
        }}
      >
        <FormInput maxLength={MAX_ZIP_CODE_LENGTH} />
      </FieldController>
    </>
  );
};
