'use client';

import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import {
  Dialog,
  FieldController,
  SelectField,
  useGlobalLoaderContext,
  useUserContext,
} from '@module/mdrt-org/shared/components';
import { patchAccountSettingsAction } from '@module/mdrt-org/shared/utils/data/patch-account-settings-action';
import { AccountSettingsFormStep } from '@module/mdrt-org/shared/utils/enums/account-settings-form-step';
import { AccountSettingsMutationKey } from '@module/mdrt-org/shared/utils/enums/account-settings-mutation-key';
import { transformYearOfFirstLicenceDefaultValue } from '@module/mdrt-org/shared/utils/helpers';
import {
  useAddDefaultErrorToast,
  useConfirmNavigation,
  useMutationHandler,
} from '@module/mdrt-org/shared/utils/hooks';
import { type Company } from '@module/mdrt-org/shared/utils/types';
import { logOut } from '@shared/misc';
import {
  Button,
  DialogActions,
  FormInput,
  FormWrapper,
  ModalTrigger,
  RenderHTML,
  type SelectOption,
} from '@shared/ui-components';
import { ButtonType, formBaseConfig, type ServerActionResponse } from '@shared/utils';

import { removeUserRoleAction } from '../../actions/remove-user-role-action';
import { useAccountSettingsPageContext } from '../../providers';
import {
  MAX_AGENT_ID_LENGTH,
  MAX_JOB_TITLE_LENGTH,
  MAX_PERSONAL_COMPANY_NAME_LENGTH,
  MIN_YEAR_OF_FIRST_LICENSE,
} from '../../utils/constants/account-settings';
import { CompanyType } from '../../utils/enums/company-type';
import { allowOnlyNumbersTransform } from '../../utils/helpers';
import {
  type ProfessionalInfoDTO,
  type ProfessionalInfoFormData,
} from '../../utils/types/form-data';

import { type AccountSettingsProfessionalInfoContent } from './get-account-settings-professional-info-content';
import styles from './professional-info-form.module.scss';

type ProfessionalInfoFormType = {
  cmsContent: AccountSettingsProfessionalInfoContent;
  companiesData: Company[];
  designationsData: SelectOption[];
  tabUrl: string;
};

export const ProfessionalInfoForm = ({
  cmsContent,
  companiesData,
  designationsData,
  tabUrl,
}: ProfessionalInfoFormType) => {
  const { hasCompanyAdminRole, personId } = useUserContext();
  const {
    countriesData,
    customerProfileContextData,
    resetCustomerProfileContextData,
    setCustomerProfileContextData,
  } = useAccountSettingsPageContext();
  const addErrorToast = useAddDefaultErrorToast();
  const { setIsNonBlockingLoaderVisible } = useGlobalLoaderContext();

  const currentYear = new Date().getFullYear();
  const minYear = currentYear - MIN_YEAR_OF_FIRST_LICENSE;

  const companiesInSelectedCountry = customerProfileContextData.country
    ? companiesData.filter(({ country }) => country === customerProfileContextData.country)
    : [];

  const getCompaniesOptions = (companyType: CompanyType) =>
    companiesInSelectedCountry
      ?.filter(
        (company) =>
          company.country === customerProfileContextData.country &&
          company.companyType === companyType
      )
      .map((company) => ({
        label: company.name,
        value: `${company.id}`,
      }));

  const affiliatedCompaniesOptions = getCompaniesOptions(CompanyType.COMPANY_AFFILIATION);
  const brokerDealerCompaniesOptions = getCompaniesOptions(CompanyType.BROKER_DEALER);
  const agencyCompaniesOptions = getCompaniesOptions(CompanyType.AGENCY);

  const getCompanyDetails = (companyId?: number | null) => {
    const company = companiesData.find((companyData) => companyData.id === companyId);
    return company ? { label: company.name, value: company.id } : null;
  };

  const currentAffiliatedCompany = getCompanyDetails(
    customerProfileContextData.affiliatedCompanyId
  );
  const currentBrokerDealerCompany = getCompanyDetails(
    customerProfileContextData.brokerDealerCompanyId
  );
  const currentAgencyCompany = getCompanyDetails(customerProfileContextData.agencyCompanyId);

  const formMethods = useForm<ProfessionalInfoFormData>({
    ...formBaseConfig,
    defaultValues: {
      affiliatedCompanyId: currentAffiliatedCompany || null,
      agencyCompanyId: currentAgencyCompany || null,
      agentId: customerProfileContextData.agentId || '',
      brokerDealerCompanyId: currentBrokerDealerCompany || null,
      country:
        countriesData.find((country) => country.countryId === customerProfileContextData.countryId)
          ?.name || '',
      designations:
        (designationsData &&
          customerProfileContextData?.designations &&
          customerProfileContextData.designations
            .map((designationId) =>
              designationsData.find((option) => option?.value === designationId)
            )
            .filter(Boolean)) ||
        null,
      jobTitle: customerProfileContextData?.jobTitle || '',
      personalCompany: customerProfileContextData.personalCompany || '',
      yearOfFirstLicense: transformYearOfFirstLicenceDefaultValue(
        customerProfileContextData.yearOfFirstLicense
      ),
    },
  });

  const {
    formState: { dirtyFields, isDirty },
    handleSubmit,
    reset,
    resetField,
  } = formMethods;

  const onFailure = (result: ServerActionResponse) => {
    if (result.status !== 200 && 'message' in result) {
      resetCustomerProfileContextData();
      addErrorToast();
    }
  };

  const { isMutationPending, mutate } = useMutationHandler({
    mutationFunction: patchAccountSettingsAction<ProfessionalInfoDTO>,
    mutationKey: [AccountSettingsMutationKey.PATCH_ACCOUNT_SETTINGS],
    onFailure,
    onSuccessHandler: () => reset({ ...formMethods.getValues() }),
  });

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const completeFormSubmit = async (data: ProfessionalInfoFormData) => {
    const formData = {
      affiliatedCompanyId: data.affiliatedCompanyId
        ? Number(data.affiliatedCompanyId?.value)
        : null,
      agencyCompanyId: data.agencyCompanyId ? Number(data.agencyCompanyId?.value) : null,
      agentId: data.agentId,
      brokerDealerCompanyId: data.brokerDealerCompanyId
        ? Number(data.brokerDealerCompanyId?.value)
        : null,
      designations: data?.designations?.length
        ? data?.designations.map((designation) => Number(designation?.value))
        : [],
      jobTitle: data.jobTitle,
      personalCompany: data.personalCompany,
      yearOfFirstLicense: data.yearOfFirstLicense ? data.yearOfFirstLicense : null,
    };

    setCustomerProfileContextData({
      ...customerProfileContextData,
      ...formData,
    });

    mutate({
      formData: {
        accountSettingStep: AccountSettingsFormStep.ProfessionalInformation,
        professionalData: formData,
      },
      personId,
    });
  };

  const { isMutationPending: isUserRoleRemovePending, mutate: removeUserRole } = useMutationHandler(
    {
      mutationFunction: removeUserRoleAction,
      onFailure: (result) => {
        if (result.status !== 200 && 'message' in result) {
          reset();
          addErrorToast();
        }
      },
      onSuccessHandler: async () => {
        await handleSubmit(async (data) => {
          await completeFormSubmit(data);
        })();
        await logOut();
      },
      showSuccessToast: false,
    }
  );

  const onCompanyChangeConfirm = () => {
    setIsConfirmationModalOpen(false);
    removeUserRole(undefined); // todo fix useMutationHandler typing
  };

  const onSubmit = async (data: ProfessionalInfoFormData) => {
    if (dirtyFields.affiliatedCompanyId && hasCompanyAdminRole) {
      setIsConfirmationModalOpen(true);

      return;
    }

    await completeFormSubmit(data);
  };

  useEffect(() => {
    setIsNonBlockingLoaderVisible(isUserRoleRemovePending);
  }, [isUserRoleRemovePending, setIsNonBlockingLoaderVisible]);

  useConfirmNavigation(isDirty, tabUrl);

  return (
    <>
      <FormWrapper
        className={styles['professional-info-form']}
        dataTestId="professional-info-form"
        formMethods={formMethods}
        onSubmit={onSubmit}
      >
        <h1 className={styles['professional-info-form__heading']}>{cmsContent.tabTitle}</h1>
        <FieldController<ProfessionalInfoFormData>
          label={cmsContent.yearOfFirstLicenseLabel}
          name="yearOfFirstLicense"
          rules={{
            max: {
              message: cmsContent.yearOfFirstLicenseValidationLabel,
              value: currentYear,
            },
            validate: {
              minYearValidation: (value) =>
                value && Number(value) < minYear
                  ? cmsContent.yearOfFirstLicenseValidationLabel
                  : true,
            },
          }}
          transform={allowOnlyNumbersTransform}
        >
          <FormInput />
        </FieldController>
        <FieldController<ProfessionalInfoFormData>
          hint={cmsContent.countryHint}
          label={cmsContent.countryLabel}
          name="country"
        >
          <FormInput disabled />
        </FieldController>
        <SelectField
          isClearable
          isDisabled={!customerProfileContextData.country}
          label={cmsContent.affiliatedCompanyLabel}
          name="affiliatedCompanyId"
          options={affiliatedCompaniesOptions}
        />
        <SelectField
          isClearable
          isDisabled={!customerProfileContextData.country}
          label={cmsContent.brokerDealerCompanyLabel}
          name="brokerDealerCompanyId"
          options={brokerDealerCompaniesOptions}
        />
        <SelectField
          isClearable
          isDisabled={!customerProfileContextData.country}
          label={cmsContent.agencyCompanyLabel}
          name="agencyCompanyId"
          options={agencyCompaniesOptions}
        />
        <FieldController<ProfessionalInfoFormData>
          label={cmsContent.personalCompanyLabel}
          name="personalCompany"
        >
          <FormInput maxLength={MAX_PERSONAL_COMPANY_NAME_LENGTH} />
        </FieldController>
        <FieldController<ProfessionalInfoFormData> label={cmsContent.jobTitleLabel} name="jobTitle">
          <FormInput maxLength={MAX_JOB_TITLE_LENGTH} />
        </FieldController>
        <SelectField
          hint={cmsContent.designationsHint}
          isMulti
          label={cmsContent.designationsLabel}
          maximumSelectedOptions={2}
          name="designations"
          options={designationsData}
        />
        <FieldController<ProfessionalInfoFormData> label={cmsContent.agentIdLabel} name="agentId">
          <FormInput maxLength={MAX_AGENT_ID_LENGTH} />
        </FieldController>
        <div className={styles['professional-info-form__footer']}>
          <Button disabled={!isDirty || isMutationPending} type={ButtonType.SUBMIT}>
            {cmsContent.saveChanges}
          </Button>
        </div>
      </FormWrapper>
      <ModalTrigger isOpen={isConfirmationModalOpen}>
        {() => (
          <Dialog
            className={styles['confirmation-modal']}
            footer={
              <DialogActions
                onPrimaryButtonClick={onCompanyChangeConfirm}
                onSecondaryButtonClick={() => {
                  resetField('affiliatedCompanyId');
                  setIsConfirmationModalOpen(false);
                }}
                primaryButtonLabel={cmsContent.confirm}
                secondaryButtonLabel={cmsContent.cancel}
              />
            }
            hasCloseButton={false}
            title={cmsContent.companyChangeAlertTitle}
          >
            <RenderHTML
              className={styles['confirmation-modal__content']}
              html={cmsContent.companyChangeAlertBody}
            />
          </Dialog>
        )}
      </ModalTrigger>
    </>
  );
};
