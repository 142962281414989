'use client';

import { type UseFormReturn } from 'react-hook-form';

import { Button, FieldController, SelectField } from '@module/mdrt-org/shared/components';
import { getCountryStates } from '@module/mdrt-org/shared/utils/data';
import { OrgSharedDataTag } from '@module/mdrt-org/shared/utils/data/constants';
import { type MembershipLevel } from '@module/mdrt-org/shared/utils/enums';
import { useQueryHandler } from '@module/mdrt-org/shared/utils/http-services';
import { SEARCH_QUERY_TRANSFORM_REGEXP, type State } from '@module/mdrt-org/shared/utils/types';
import { Card, FormInput, FormWrapper, type SelectOption } from '@shared/ui-components';
import { allowedRegExpTransform } from '@shared/utils';

import { type MemberDirectoryPageContent } from '../get-member-directory-page-content';
import { type SearchFormData } from '../search/search';

import styles from './search-form.module.scss';

export const SearchForm = ({
  canFilterByMembershipLevel,
  cmsContent,
  countryOptions,
  formMethods,
  initialCountryStatesData,
  membershipLevelOptions,
  onSubmit,
}: {
  canFilterByMembershipLevel: boolean;
  cmsContent: MemberDirectoryPageContent;
  countryOptions: Array<SelectOption<number>>;
  formMethods: UseFormReturn<SearchFormData>;
  initialCountryStatesData: State[] | undefined;
  membershipLevelOptions: Array<SelectOption<MembershipLevel>>;
  onSubmit: (data: SearchFormData) => Promise<void> | void;
}) => {
  const { formState, watch } = formMethods;

  const selectedCountryId = watch('country')?.value ?? null;
  const { data: countryStatesData, isLoading: isCountryStatesDataLoading } = useQueryHandler({
    enabled: Boolean(selectedCountryId && formState.touchedFields.country),
    initialData: initialCountryStatesData,
    queryFn: async () => await getCountryStates({ countryId: selectedCountryId }),
    queryKey: [
      OrgSharedDataTag.GET_COUNTRY_STATES,
      `${OrgSharedDataTag.GET_COUNTRY_STATES}_${selectedCountryId}`,
    ],
  });

  const stateOptions =
    countryStatesData?.map((state) => ({
      label: state.stateName,
      value: state.id,
    })) ?? [];

  const allowedQueryTransform = allowedRegExpTransform(SEARCH_QUERY_TRANSFORM_REGEXP);

  return (
    <Card className={styles['search-form-container']} elevation={1}>
      <FormWrapper
        className={styles['form']}
        dataTestId="member-directory-search"
        formMethods={formMethods}
        onSubmit={onSubmit}
      >
        <div className={styles['form__search-field-container']}>
          <FieldController
            label={cmsContent.searchInputLabel}
            name="searchQuery"
            transform={allowedQueryTransform}
          >
            <FormInput />
          </FieldController>
        </div>
        <div className={styles['form__address-fields']}>
          <div className={styles['form__address-fields-item']}>
            <SelectField
              isClearable
              isSearchable
              label={cmsContent.countryLabel}
              name="country"
              onChange={() => {
                formMethods.setValue('stateOrProvince', null);
              }}
              options={countryOptions}
            />
          </div>
          <div className={styles['form__address-fields-item']}>
            <SelectField
              isClearable
              isDisabled={stateOptions.length === 0 || isCountryStatesDataLoading}
              isSearchable
              label={cmsContent.stateLabel}
              name="stateOrProvince"
              options={stateOptions}
            />
          </div>
          <div className={styles['form__address-fields-item']}>
            <FieldController
              label={cmsContent.cityLabel}
              name="city"
              transform={allowedQueryTransform}
            >
              <FormInput />
            </FieldController>
          </div>
        </div>
        {canFilterByMembershipLevel && (
          <div className={styles['form__membership-type-field-container']}>
            <SelectField
              isClearable
              label={cmsContent.membershipLevelLabel}
              name="membershipLevel"
              options={membershipLevelOptions}
            />
          </div>
        )}
        <div className={styles['form__search-button-container']}>
          <Button className={styles['form__button']} type="submit">
            {cmsContent.searchButtonLabel}
          </Button>
        </div>
      </FormWrapper>
    </Card>
  );
};
