import { useSearchPageSearchParameters } from './use-search-page-search-parameters';

const getSectionCount = (field: string[] | undefined): number => field?.length ?? 0;

export const useAppliedFilterCount = (isAuthenticated: boolean): number => {
  const { savedContent, source, topic, year } = useSearchPageSearchParameters();

  return (
    getSectionCount(source) +
    getSectionCount(topic) +
    getSectionCount(year) +
    (isAuthenticated && savedContent ? 1 : 0)
  );
};
