import {
  SEARCH_CONTENT_TYPE_PARAM_KEY,
  SEARCH_PAGE_PARAM_KEY,
  SEARCH_PERSON_PARAM_KEY,
  SEARCH_QUERY_PARAM_KEY,
  SEARCH_SAVED_CONTENT_PARAM_KEY,
  SEARCH_SOURCE_PARAM_KEY,
  SEARCH_TOPIC_PARAM_KEY,
  SEARCH_YEAR_PARAM_KEY,
} from '@module/mdrt-org/shared/utils/constants';
import { type PageProps } from '@shared/utils';

import { getValidContentType } from './get-valid-content-type';
import { getValidPageIndex } from './get-valid-page-index';
import { parseSearchStringAndGetCustomFilters } from './helpers';

const handleMultipleParameterValues = (
  parameter: PageProps['searchParams'][string]
): string | undefined => {
  return Array.isArray(parameter) ? parameter[0] : parameter;
};

export const getNormalizedSearchPageSearchParameters = (
  searchPageSearchParameters: PageProps['searchParams']
) => {
  const getParameterValue = (key: string) =>
    handleMultipleParameterValues(searchPageSearchParameters?.[key]);

  const pageIndex = getValidPageIndex(getParameterValue(SEARCH_PAGE_PARAM_KEY));

  const searchString = getParameterValue(SEARCH_QUERY_PARAM_KEY) ?? '';

  const { customFilter: searchPerson, text: searchTerm } = parseSearchStringAndGetCustomFilters(
    searchString,
    SEARCH_PERSON_PARAM_KEY
  );

  const contentType = getValidContentType(getParameterValue(SEARCH_CONTENT_TYPE_PARAM_KEY));

  const year = getParameterValue(SEARCH_YEAR_PARAM_KEY)?.split(',');

  const source = getParameterValue(SEARCH_SOURCE_PARAM_KEY)?.split(',');

  const topic = getParameterValue(SEARCH_TOPIC_PARAM_KEY)?.split(',');

  const savedContent = getParameterValue(SEARCH_SAVED_CONTENT_PARAM_KEY);

  return {
    contentType,
    pageIndex,
    savedContent,
    searchPerson,
    searchTerm,
    source,
    topic,
    year,
  };
};
