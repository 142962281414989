import { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { useUserContext } from '@module/mdrt-org/shared/components';
import { Accordion, CheckboxField } from '@shared/ui-components';

import { type SearchCmsContent } from '../../../../graphql/get-search-cms-content';
import {
  type AdvancedFilterOptions,
  type FilterSection,
  type AdvancedFilterFormData,
} from '../../types/advanced-filter-form-data';
import { getCheckedItemCount, getSectionLabel } from '../../utils';
import { FilterOptions } from '../filter-options/filter-options';

import styles from './filter-content.module.scss';

type FilterContentProps = {
  filterOptions: AdvancedFilterOptions;
  searchPageCmsContent: SearchCmsContent['searchPage'];
};

export const FilterContent = ({ filterOptions, searchPageCmsContent }: FilterContentProps) => {
  const { status } = useUserContext();
  const isAuthenticated = status === 'authenticated';
  const [activeSection, setActiveSection] = useState<FilterSection | null>(null);
  const { watch } = useFormContext<AdvancedFilterFormData>();

  const checkedCount = getCheckedItemCount(watch());

  const handleSectionToggle = (sectionId: FilterSection) => {
    if (activeSection === sectionId) {
      setActiveSection(null);
    } else {
      setActiveSection(sectionId);
    }
  };

  const getSectionControlProps = (sectionId: FilterSection) => ({
    isExpanded: activeSection === sectionId,
    onChange: () => handleSectionToggle(sectionId),
  });

  return (
    <ul className={styles['filter-stack']}>
      <li className={styles['filter-stack__item']}>
        <Accordion
          headerLabel={getSectionLabel(
            searchPageCmsContent?.SourceLabel || 'Source',
            checkedCount.source
          )}
          {...getSectionControlProps('source')}
          iconPosition="right"
          withPlusMinusIcon
        >
          <FilterOptions filterOptions={filterOptions.source} sectionName="source" />
        </Accordion>
      </li>
      <li className={styles['filter-stack__item']}>
        <Accordion
          headerLabel={getSectionLabel(
            searchPageCmsContent?.TopicLabel || 'Topic',
            checkedCount.topic
          )}
          {...getSectionControlProps('topic')}
          iconPosition="right"
          withPlusMinusIcon
        >
          <FilterOptions filterOptions={filterOptions.topic} sectionName="topic" />
        </Accordion>
      </li>
      <li className={styles['filter-stack__item']}>
        <Accordion
          headerLabel={getSectionLabel(
            searchPageCmsContent?.YearLabel || 'Year',
            checkedCount.year
          )}
          {...getSectionControlProps('year')}
          iconPosition="right"
          withPlusMinusIcon
        >
          <FilterOptions filterOptions={filterOptions.year} sectionName="year" />
        </Accordion>
      </li>
      <li className={styles['filter-stack__item']}>
        {isAuthenticated && (
          <CheckboxField
            isSwitch
            label={searchPageCmsContent?.SavedContentLabel || 'Saved content'}
            name={`savedContent.${filterOptions.savedContent[0].name}`}
            pushToRight
            wrapperClassName={styles['switch-filter']}
          />
        )}
      </li>
    </ul>
  );
};
